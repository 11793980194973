<template>
  <div class="active-dark">
    <!-- Start Header Area -->
    <PreviewHeader>
      <img slot="logo" :src="logo" />
    </PreviewHeader>
    <!-- End Header Area -->

    <!-- Start Banner Area  -->
    <div class="pv-slider-area slider-wrapper">
      <div class="slider-activation">
        <div
          class="
            slide slide-style-1
            slider-fixed--height
            d-flex
            align-center
            justify-center
            bg_image bg_image--34
          "
          data-black-overlay="9"
        >
          <div class="container position-relative">
            <div class="row">
              <div class="col-lg-12">
                <div class="inner">
                  <h1 class="heading-title theme-gradient">
                    Cambia la configuración de la pagina
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Banner Area  -->

    <!-- Start Main Demo Area  -->
    <div class="main-wrapper">
      <!-- Start Home Demo  -->
      <div class="home-demo-area bg_color--1 ptb--120" id="view-demo">
        <div class="wrapper plr--120 rn-masonary-wrapper">
          <v-row>
            <v-col cols="12">
              <div class="text-center section-title pb--30">
                <h2 class="heading-title theme-gradient">Escoge tu diseño</h2>
                <p>
                  Todos los diseños son realizados para funcionar en todas las
                  plataformas
                </p>
              </div>
            </v-col>
          </v-row>
          <div class="be-custom-gallery main-page-preview-gallery">
            <!-- <v-tabs v-model="tab" centered flat hide-slider color="primary">
              <v-tab :ripple="false" v-for="item in tabItems" :key="item.id">{{
                item.name
              }}</v-tab>
            </v-tabs> -->

            <div
              class="gallery-wrapper gallery-grid mesonry-list grid-metro3"
              id="animated-thumbnials"
            >
              <div
                v-for="(item, i) in content"
                :key="i"
                class="single-demo image masonry_item portfolio-33-33"
              >
                <a rel="noopener" @click="Layout(item.id)">
                  <img :src="item.src" :alt="item.alt" />
                  <h3 class="heading-title">
                    {{ item.title }}
                    <span v-if="item.active" :class="'label-new'">
                      {{ item.sticker }}
                    </span>
                  </h3>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Home Demo  -->

      <!-- Start Inner Page Demo  -->

      <!-- End Inner Page Demo  -->

      <!-- Start Feature Area  -->

      <!-- End Feature Area  -->

      <!-- Start Call To Action Area  -->
      <!-- <footer
                class="pv-callto-action call-to-action-wrapper text-white-wrapper call-to-action ptb--120 bg_image bg_image--34"
                data-black-overlay="9"
            >
                <v-container>
                    <v-row>
                        <v-col lg="12">
                            <div class="text-center inner">
                                <span
                                    ><span
                                        >Purchase The Imroz and Make Your Site
                                        super faster and easy.</span
                                    ></span
                                >
                                <h2 class="mt--20 theme-gradient font-700">
                                    Let's go to Purchase
                                </h2>
                                <div class="footer-btn mt--35">
                                    <a
                                        class="btn-default"
                                        target="_blank"
                                        href="https://themeforest.net/checkout/from_item/32012548?license=regular"
                                        ><span>Purchase Now</span></a
                                    >
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </footer> -->
      <!-- End Call To Action Area  -->
    </div>
    <!-- End Main Demo Area  -->
  </div>
  <!-- End Page Wrapper  -->
</template>

<script>
import feather from "feather-icons";
import PreviewHeader from "../components/header/PreviewHeader";
export default {
  components: {
    PreviewHeader,
  },
  name: "Home",
  data() {
    return {
      logo: require("../assets/images/logo/logo.png"),
      tab: null,
      content: [
        {
          id: 1,
          src: require("../assets/images/preview/studio-agency.png"),
          title: "Studio Agency",
          alt: "Studio Agency",
          href: "studio-agency",
          active: false,
        },

        {
          id: 7,
          src: require("../assets/images/preview/portfolio-home.png"),
          title: "Minimal",
          alt: "Creative Portfolio",
          href: "/creative-portfolio",
          sticker: "New",
          active: false,
        },
        {
          id: 6,
          src: require("../assets/images/preview/homeparticles.png"),
          title: "LandingHomeParticle",
          alt: "LandingHomeParticle",
          href: "/creative-portfolio",
          sticker: "New",
          active: false,
        },
        {
          src: require("../assets/images/preview/coming-soon.png"),
          title: "Coming Soon",
          alt: "LandingHomeParticle",

          sticker: "New",
          active: false,
        },
      ],
      panel: 0,
    };
  },
  created() {
    this.Designs();
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
    Layout(id) {
      if (id) {
        this.$store.state.iglesia.Layout = id;
        this.$store.dispatch("update_layout", { id: id }).then((res) => {
          setTimeout(() => {
            // this.$store.dispatch(
            //     "get_Iglesia",
            //     this.$route.params.Nombre_Id
            // );
            this.$router.push(`/${this.$route.params.Nombre_Id}`);
          }, 1500);
        });
      }
    },
    Designs() {
      if (this.$store.state.iglesia.Id_Oferta == 1) {
        this.content = [
        //   {
        //     id: 1,
        //     src: require("../assets/images/preview/studio-agency.png"),
        //     title: "Studio Agency",
        //     alt: "Studio Agency",
        //     href: "studio-agency",
        //     active: false,
        //   },

          {
            id: 7,
            src: require("../assets/images/preview/portfolio-home.png"),
            title: "Minimal",
            alt: "Creative Portfolio",
            href: "/creative-portfolio",
            sticker: "New",
            active: false,
          },
          //   {
          //     id: 6,
          //     src: require("../assets/images/preview/homeparticles.png"),
          //     title: "LandingHomeParticle",
          //     alt: "LandingHomeParticle",
          //     href: "/creative-portfolio",
          //     sticker: "New",
          //     active: false,
          //   },
          //   {
          //     src: require("../assets/images/preview/coming-soon.png"),
          //     title: "Coming Soon",
          //     alt: "LandingHomeParticle",

          //     sticker: "New",
          //     active: false,
          //   },
        ];
      }
      if (this.$store.state.iglesia.Id_Oferta == 2) {
        this.content = [
          {
            id: 1,
            src: require("../assets/images/preview/studio-agency.png"),
            title: "Studio Agency",
            alt: "Studio Agency",
            href: "studio-agency",
            active: false,
          },

          {
            id: 7,
            src: require("../assets/images/preview/portfolio-home.png"),
            title: "Minimal",
            alt: "Creative Portfolio",
            href: "/creative-portfolio",
            sticker: "New",
            active: false,
          },
          //   {
          //     id: 6,
          //     src: require("../assets/images/preview/homeparticles.png"),
          //     title: "LandingHomeParticle",
          //     alt: "LandingHomeParticle",
          //     href: "/creative-portfolio",
          //     sticker: "New",
          //     active: false,
          //   },
          //   {
          //     src: require("../assets/images/preview/coming-soon.png"),
          //     title: "Coming Soon",
          //     alt: "LandingHomeParticle",

          //     sticker: "New",
          //     active: false,
          //   },
        ];
      }
      if (this.$store.state.iglesia.Id_Oferta == 3) {
        this.content = [
          {
            id: 1,
            src: require("../assets/images/preview/studio-agency.png"),
            title: "Studio Agency",
            alt: "Studio Agency",
            href: "studio-agency",
            active: false,
          },

          {
            id: 7,
            src: require("../assets/images/preview/portfolio-home.png"),
            title: "Minimal",
            alt: "Creative Portfolio",
            href: "/creative-portfolio",
            sticker: "New",
            active: false,
          },
          {
            id: 6,
            src: require("../assets/images/preview/homeparticles.png"),
            title: "LandingHomeParticle",
            alt: "LandingHomeParticle",
            href: "/creative-portfolio",
            sticker: "New",
            active: false,
          },
          {
            src: require("../assets/images/preview/coming-soon.png"),
            title: "Coming Soon",
            alt: "LandingHomeParticle",

            sticker: "New",
            active: false,
          },
        ];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.theme--light.about-expension-panels .v-expansion-panel-header::after {
  height: 1px;
  width: 100%;
}
.theme--light.about-expension-panels .v-expansion-panel-header {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 0 10px 0;
  font-size: 18px;
}
.main-page-preview-gallery {
  .v-tabs {
    margin: 35px auto 30px;
  }
}
.v-expansion-panel-content__wrap {
  p {
    a {
      font-weight: 500;
    }
  }
}
</style>
<style lang="scss">
.header-area .logo a img {
  max-height: 60px;
}
.faq-area {
  strong {
    color: var(--color-primary);
    font-weight: 700;
  }
}
@media only screen and (max-width: 767px) {
  .home-demo-area.inner-page-demo {
    .single-demo {
      padding: 0;
    }
    .wrapper {
      padding: 0 20px !important;
    }
  }
  .prv-feature {
    .plr_sm--30 {
      padding: 0 20px !important;
    }
  }
}
</style>
